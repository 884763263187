<template>
  <div>
    <!-- select 2 demo -->

    <b-modal id="modal-login" centered ok-only size="lg" no-close-on-backdrop hide-footer="true" title="Privacy Policy">
      <b-form>
        <vue-editor ref="name" v-model="myObj.privacy_and_policy"></vue-editor>
        <!-- <b-form-group label="Policy" invalid-feedback="Policy is required.">
          <b-form-textarea
            ref="name"
            placeholder="Enter privacy policy here."
            required
            @focusout="CheckName()"
            v-model="myObj.privacy_and_policy"
            rows="7"
          />
        </b-form-group> -->
      </b-form>
      <br />
      <div style="">
        <b-button class="float-right ml-5" variant="primary" @click="AddFlavour()">
          <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
          <span v-if="!request" class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <b-card no-body class="mb-0 p-1">
      <div class="mt-1 ml-1">
        <b-row>
          <b-col class="mt-2" md="10" sm="8">
            <div class="d-flex align-items-center justify-content-start">
              <b-button @click="FilterLang('english')" variant="warning" class="mr-1">
                <span class="text-nowrap">English</span>
              </b-button>
              <b-button @click="FilterLang('arabic')" variant="success" class="mr-1">
                <span class="text-nowrap">Arabic</span>
              </b-button>
              <b-button @click="FilterLang('french')" variant="info" class="mr-1">
                <span class="text-nowrap">French</span>
              </b-button>
            </div>
            <!-- <b-form-group label="Language">
              <v-select
                placeholder="Select language."
                v-model="filterLang"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="langOptions"
                @input="FilterLang()"
                :clearable="false"
              />
            </b-form-group> -->
          </b-col>
        </b-row>
      </div>
      <div class="m-1">
        <VueTrix ref="name" v-model="myObj.privacy_and_policy" placeholder="Enter content" localStorage />
        <div style="margin: 10px 0px;">
          <b-button class="float-right ml-5" variant="primary" :disabled="request" @click="AddFlavour()">
            <b-spinner v-if="request" variant="light" type="grow" small label="Spinning"></b-spinner>
            <span v-if="!request" class="text-nowrap">Save</span>
          </b-button>
        </div>
        <!-- <b-table :items="items" :fields="fields" striped>
          <template #cell(#)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(icon)="data">
            <b-img
              :src="data.value"
              style="width: 100px; height: 100px; object-fit: cover"
              rounded
              alt="Rounded image"
            />
          </template>

          <template #cell(actions)="data">
            <template>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                v-b-tooltip.hover.v-primary
                v-b-tooltip.placement.left
                title="Edit"
                @click="OpenAddModal(data.item.id)"
              >
                <feather-icon size="16" icon="EditIcon" />
              </b-button>
            </template>
          </template>
        </b-table> -->
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BSpinner,
  BFormCheckbox,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { BFormSelect } from "bootstrap-vue";
//import Multiselect from "vue-multiselect";
import VueGallery from "vue-gallery";
import Ripple from "vue-ripple-directive";
import VueTrix from "vue-trix";

export default {
  components: {
    VueTrix,
    //Multiselect,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BMediaAside,
    BSpinner,
    BImg,
    BMedia,
    BFormGroup,
    //BAvatar,
    BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    BFormCheckbox,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData("english");
  },
  data() {
    return {
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJPc2FtYSIsImVtYWlsIjoib3NhbWF1c21hbjU1NUBnbWFpbC5jb20iLCJqdGkiOiJjMzI0MGI0ZS1mM2NlLTQxZGUtYmRmNS1iYTE1YTFjMjkyZDIiLCJleHAiOjE2NzA1NjU2MjQsImlzcyI6ImFwcGlja3NvbHV0aW9ucy5pby5jb20iLCJhdWQiOiJhcHBpY2tzb2x1dGlvbnMuaW8uY29tIn0.0_pK3DJcXTTEAk-fFziiMOkgGUC7nUYXppJ9C7dOIic",
      plus: "",
      fileProfile: "",
      logoloading: false,
      errors: {
        status: false,
      },
      statusOptions: ["active", "inactive"],
      index: null,
      fields: ["#", { label: "Policy", key: "privacy_and_policy" }, "actions"],
      filterStatus: "",
      items: [],
      request: false,
      categoryOptions: [],
      myObj: {
        id: 0,
        privacy_and_policy: "",
        language: "",
      },
      searchQuery: "",
      isUpdating: false,
      langOptions: ["English", "Arabic", "French"],
      filterLang: [],
    };
  },
  methods: {
    SearchData() {
      if (this.searchQuery.length > 0) {
        var data = this.items.filter(
          (data) =>
            JSON.stringify(data)
              .toLowerCase()
              .indexOf(this.searchQuery.toLowerCase()) !== -1
        );
        this.items = data;
      } else {
        this.LoadData();
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.privacy_and_policy.trim() == "") {
        console.log(this.myObj);
        return (elem.state = false);
      } else {
        console.log(this.myObj);
        return (elem.state = true);
      }
    },
    CheckImage() {
      console.log(this.myObj.icon);
      var elem = this.$refs["image"];
      if (this.myObj.icon == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckStatus() {
      if (this.isUpdating) {
        var elem = this.$refs["status"];
        if (this.myObj.status == "") {
          return (elem.state = false);
        } else {
          return (elem.state = true);
        }
      } else return true;
    },
    OpenAddModal(id) {
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Privacy/" + id,
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.myObj = response.data.data;
          this.$bvModal.show("modal-login");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require("axios");
          var config = {
            method: "delete",
            url: "https://api.geotalent.co/api/Privacy/" + id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
          };
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                Swal.fire(
                  "Success!",
                  "Policy has been deleted.",
                  "success"
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      console.log(this.fileProfile);
      var axios = require("axios");
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        formData.append("file", this.fileProfile);
        axios
          .post("https://geoupload.appick.io/", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })

          .then((e) => {
            console.log(e.data);
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            this.plus = fn1;
            this.myObj.icon = this.plus;
            // console.log(this.plus);
            this.logoloading = "loaded";
            this.CheckImage();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.icon = "";
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    AddFlavour() {
      this.CheckName();
      if (this.CheckName() == false) {
        return this.$bvToast.toast("Please enter the policy", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-bottom-center",
        });
      } else {
        this.request = true;
        if (this.myObj.id == 0) {
          console.log("Obj", this.myObj);
          var axios = require("axios");
          var config = {
            method: "post",
            url: "https://api.geotalent.co/api/Privacy",
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                this.LoadData(this.myObj.language);
                this.request = false;
                this.$bvToast.toast("Policy Added.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
                this.request = false;
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        } else {
          //Edit
          console.log("edit_Obj", this.myObj);
          var axios = require("axios");
          var config = {
            method: "put",
            url: "https://api.geotalent.co/api/Privacy/" + this.myObj.id,
            headers: {
              Authorization: "bearer " + this.$store.state.userData.token,
            },
            data: this.myObj,
          };
          axios(config)
            .then((response) => {
              console.log(response.data);
              if (response.data.status === "success") {
                this.LoadData(this.myObj.language);
                this.request = false;
                this.$bvToast.toast("Policy updated.", {
                  title: "Success!",
                  variant: "success",
                  toaster: "b-toaster-top-center",
                });
              } else {
                this.$bvToast.toast("Something went wrong.", {
                  title: "Error!",
                  variant: "danger",
                  toaster: "b-toaster-bottom-center",
                });
                this.request = false;
              }
            })
            .catch(function(error) {
              console.log(error);
            });
        }
      }
    },
    async LoadData(text) {
      console.log(text);
      var axios = require("axios");
      var config = {
        method: "get",
        url: "https://api.geotalent.co/api/Privacy",
        headers: {
          Authorization: "bearer " + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          console.log(response);
          this.items = response.data.data;
          this.FilterLang(text);
          // this.myObj = this.items[0];
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    FilterLang(text) {
      this.filterLang = this.items.filter((el) => el.language == text);
      console.log(this.filterLang);
      if (this.filterLang.length != 0) {
        this.myObj = this.filterLang[0];
      } else {
        this.myObj = {
          id: 0,
          privacy_and_policy: "",
          language: text,
        };
        console.log(this.myObj);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}

trix-editor {
  min-height: 600px;
}
</style>
